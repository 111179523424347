import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useIntl } from 'components/Intl';

const ChatsListSkeleton = () => {
  const intl = useIntl();

  return (
    <Grid
      item
      container
      direction="column"
      wrap="nowrap"
      rowGap={1}
      role="progressbar"
      aria-label={intl.formatMessage({ id: 'aria.label.loading' })}
    >
      <Skeleton animation="wave" width="100%" />

      <Skeleton variant="rectangular" width="100%" height={40} />
      <Skeleton variant="rectangular" width="100%" height={40} />
    </Grid>
  );
};

export default ChatsListSkeleton;
